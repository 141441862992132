var exports = {};
exports = direction;
var RTL = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC";
var LTR = "A-Za-z\xC0-\xD6\xD8-\xF6" + "\xF8-\u02B8\u0300-\u0590\u0800-\u1FFF\u200E\u2C00-\uFB1C" + "\uFE00-\uFE6F\uFEFD-\uFFFF";
var rtl = new RegExp("^[^" + LTR + "]*[" + RTL + "]");
var ltr = new RegExp("^[^" + RTL + "]*[" + LTR + "]");

function direction(value) {
  value = String(value || "");

  if (rtl.test(value)) {
    return "rtl";
  }

  if (ltr.test(value)) {
    return "ltr";
  }

  return "neutral";
}

export default exports;